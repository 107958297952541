import React from 'react'
import { Link } from 'gatsby'

const MetallicaMenu = (props) => (
  <div>
  <h2 className="major">Metallica</h2>
  <ul className="bigList">
  <li className={props.pageName === '2017_tour' ? 'current' : ''}><Link to="/clients/metallica/2017_tour">2017 US Tour</Link></li>
  <li className={props.pageName === '2018_tour' ? 'current' : ''}><Link to="/clients/metallica/2018_tour">2018-2019 US Tour</Link></li>
  <li className={props.pageName === '2019_europe_tour' ? 'current' : ''}><Link to="/clients/metallica/2019_europe_tour">2019 European Tour</Link></li>
  </ul>
  </div>
)

export default MetallicaMenu
