import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import MetallicaMenu from './_menu.js'

const Metallica2019EuropeanTourPage = ({ data }) => (
  <Layout bgClass="clients-metallica">
    <MetallicaMenu pageName="2019_europe_tour" />
    <div className="textPageDetails">
      <p>
      The “Enhanced Experience” VIP poster series for the European leg of Metallica’s 2019 World Wired tour. Metallica required 25 unique posters for each of the 25 shows, and Nakatomi brought in artists from a wide variety of disciplines including comic book illustrators and sculptors.
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

Metallica2019EuropeanTourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Metallica2019EuropeanTourPage

export const pageQuery = graphql`
  query Metallica2019EuropeanTourPageIndexQuery {
    allFile(
      filter: {
        extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/metallica/2019_europe_tour/"}}
        sort: {fields: name}
      ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
